<template>
  <div>
    <v-container class="pb-1 listing-margin">
      <h2>Что хотите сдать?</h2>
      <v-item-group>
        <v-row class="category-block">
            <v-col
              v-for="(item, n) in this.categories"
              :key="n"
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="mt-1 mb-1 pt-0 pb-1"
            >
              <v-hover v-slot="{ hover }">
                <v-item>
                  <v-card
                    elevation="2"
                    outlined
                    :class=" 'd-flex '+ ( !hover ? '' : 'noactive' )"
                    @click="selectCategory(item)"
                  >
                    <v-list-item>
                      <v-list-item-avatar
                        tile
                        rounded="8"
                        size="96"
                        class="mt-3 mb-3 mr-3"
                      >
                        <v-img :src="item.icon"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="text-title">
                          {{ item.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-subtitle">{{ item.comment }}</v-list-item-subtitle>
                       </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-item>
              </v-hover>
            </v-col>
        </v-row>
      </v-item-group>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ['categories'],
  data: () => ({}),
  methods: {
    selectCategory (item) {
      this.$emit('selected', item)
    }
  }
}
</script>
<style>
.noactive {
  opacity: 0.9;
}

@media (max-width: 960px) {

}

@media (max-width: 600px) {

}

/* iphone-5 */
@media (max-width: 400px) {
  .text-item {
    line-height: 1rem !important;
  }
}

.ordersClass {
  text-align: center;
  font-size: 18px;
}

.text-item {
  padding: 5px 0;
  font-size: 20px;
  background: #fff;
  opacity: 0.5;
  border-radius: 0 !important;
}

/*.v-sheet.v-card {*/
/*  border-radius: 0px !important;*/
/*}*/

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

@media (max-width: 960px) {
  .category-block .col-6 {
    padding: 6px
  }
}

@media (max-width: 600px) {
  .category-block .col-6 {
    padding: 4px
  }
}

@media (max-width: 400px) {
  .category-block .col-6 {
    padding: 4px;
  }
}

.v-card.on-hover.theme--dark {
  background-color: rgba(#FFF, 0.8)
}

.v-card.on-hover.theme--dark.v-card__text {
  color: #000
}

.theme--light.v-card {
  background: #F5F5F5 !important;
}

.v-sheet.v-card {
  border: 0.33px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;

}

.v-list-item {
  padding: 0 12px !important;
}

.text-title {
  font-size: 16px !important;
  line-height: 20px!important;
  font-weight: 500;
}

.text-subtitle {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 400;
}

.v-radio {
  align-items: baseline !important;
}

</style>
