<template>
  <v-container class="pb-2" order-page>
    <v-row>
      <v-col cols="12" lg="6">
        <p>Имя</p>
        <v-text-field
          outlined
          dense
          solo
          flat
          hide-details = "auto"
          placeholder="Введите имя"
          hint=""
          v-model="user.name"
          @input="user.name = capitalize(user.name)"
          @keyup.native="user.name = removeSymbols(user.name)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <p>Телефон</p>
        <v-text-field
          outlined
          dense
          solo
          flat
          hide-details = "auto"
          v-model="user.phone"
          placeholder="+7-999-999-99-99"
          v-mask="'+7-###-###-##-##'"
          :rules="[rules.required, rules.phone]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <p>Откуда забирать</p>
        <v-text-field
          outlined
          dense
          solo
          flat
          hide-details = "auto"
          placeholder="Садовая, 26"
          v-model="user.address"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="agreement">
      <p>* Нажимая кнопку "ОФОРМИТЬ ЗАКАЗ", вы даете согласие на обработку ваших персональных данных:
        <router-link to="/privacy">Политика конфиденциальности</router-link>
      </p>
    </div>
  </v-container>

</template>
<script>
import { mask } from 'vue-the-mask'
export default {
  props: ['user'],
  directives: { mask },

  data: () => ({
    rules: {
      required: v => !!v || 'Обязательное поле',
      russianLiters: v => v === '' || /^[А-ЯЁа-яё\- ]+$/.test(v) || 'Допускаются только русские буквы',
      phone: v => /^\+7-\d{3}-\d{3}-\d{2}-\d{2}$/.test(v) || 'Телефон должен содержать 11 цифр'
    }
  }),
  methods: {
    capitalize: function (val) {
      if (!val) return ''
      return val
        .toString()
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
        .split('-')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join('-')
    },

    removeSymbols: function (val) {
      if (!val) return ''
      return val
        .toString()
        .replace(/[ ]+/g, ' ')
        .replace(/[-]+/g, '-')
        .replace(/[^A-Za-zА-ЯЁа-яё\- ]/g, '')
        .replace(/^[ |-]/g, '')
        .replace(/ -/g, '-')
        .replace(/- /g, '-')
    }
  }
}
</script>
<style>
.agreement p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px !important;
  line-height: 13px;
  color: #000000 !important;
}

.agreement p a {
  color: #000000 !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 44px;
}

.v-application p {
  margin-bottom: 4px !important;
}

.order-page {
  margin-top: 43px;
}

.order-page p {
  margin-bottom: 7px !important;
  color: #6D7885;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.order-page .theme--light.v-input input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.order-page .row {
  margin-bottom: 5px;
}
</style>
