<template>
  <v-container class="pb-2 listing-margin">
    <h2>Что хотите сдать?</h2>
    <div class="listing">
      <v-item-group>
        <v-row>
          <v-col cols="12">
            <v-radio-group class="items" v-model="selected.type">
              <div v-for="(item, i) in this.types" :key="i">
                <v-radio
                  color="blue darken-2 lighten-1"
                  :label="item.title"
                  :value="item.id"
                  @click="selectType(item)"
                  class="radio-select"
                ></v-radio>
                <div v-if="item.info" class="item-info">({{item.info}})</div>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-item-group>
      <VueFileAgent
        v-model="selected.uploads"
        :uploadUrl="uploadUrl"
        :accept="'image/*'"
        :maxSize="'10MB'"
        :maxFiles="4"
        :meta="false"
        :deletable="true"
        :helpText="'Загрузить фото'"
        :errorText="{
        type: 'Возможна загрузка загружать только изображений',
        size: 'Размер файла не должен превышать 10 Мб',
      }"
        :uploadHeaders="{
        'Access-Control-Allow-Origin': '*'
      }"
        @upload="uploadEvent('upload', $event)"
        @upload:error="uploadEvent('upload:error', $event)"
        @upload:delete="uploadEvent('upload:delete', $event)"
        @upload:delete:error="uploadEvent('upload:delete:error', $event)"
        @upload:update="uploadEvent('upload:update', $event)"
        @upload:update:error="uploadEvent('upload:update:error', $event)"
      >
        <template v-slot:file-preview-new>
          <div class="file-input-custom" key="new">
            <a>Отправьте фото мы определим категорию и стоимость</a>
          </div>
        </template>
      </VueFileAgent>
    </div>
  </v-container>
</template>
<script>
export default {
  props: ['types', 'selected'],
  data: () => ({
    uploadUrl: process.env.VUE_APP_BACKEND_HOST + '/api/upload/photo'
  }),
  methods: {
    selectType (item) {
      this.$emit('selected', item)
    },
    uploadEvent (eventName, data) {
      const uploads = this.selected.uploads.map(item => { return item.upload.data })
      this.$emit('uploaded', uploads)
    }
  }
}
</script>
<style>
.item-info {
  padding-left: 38px;
  font-size: 15px;
  top: -5px;
  position: relative;
}

h2 {
  font-size: 22px;
  font-weight: normal;
  margin-left: 12px;
  margin-bottom: 35px;
}

.listing-margin {
  margin-top: 54px;
}

.listing {
  padding-left: 20px;
  padding-right: 16px;
  padding-top: 0px;
  top: -4px;
  position: relative;
}

.listing .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px !important;
}

.listing div.v-radio.radio-select.theme--light > label {
  line-height: 24px !important;
}

.listing .v-icon.v-icon {
  font-size: 28px;
  top:3px
}

.v-input--selection-controls.v-input {
  margin-top:0;
  /*background: #0b4d75;*/
}

.radio-select {
  margin-top: 21px !important;
}

.vue-file-agent.file-input-wrapper {
  border: 0px dashed #aaa;
  z-index: 2;
}

.container {
  padding: 16px;
}

.v-item-group {
  /*padding-left: 11px;*/
}

.theme--light.v-label {
  color: #000000 !important;
}

.theme--light.v-icon {
  color: #B8C1CC;

}

.v-label {
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding-left: 5px;
}

.v-application .file-input-custom a {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #4675AF;
  background: url("/img/prod/file-input-icon.svg") no-repeat left center;
  padding-left: 40px;
  display: block;
  text-align: left;
  position: relative;
  left: -3px;
  margin-top: 4px;
}
</style>
