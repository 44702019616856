<template>
  <v-dialog
    v-model="$parent.showErrorDialog"
    max-width="500px"
    content-class="dialog-result"
  >
    <v-card>
      <v-card-title>
        <span>Произошла ошибка</span>
      </v-card-title>
      <v-card-text>
        <p>
          Из-за проблем с интернетом мы не получили ваш заказ.
          Попробуйте отправить его снова либо связаться с нами
          по номеру телефона Trash Panda:
          <a :href="'tel:+' + $parent.settings.company_phone">+{{$parent.settings.companyPhone}}</a>
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'OrderErrorDialog',
  props: [],
  data: () => ({})
}
</script>
<style scoped>
.dialog-result.v-dialog > .v-card > .v-card__title {
  padding: 22px 24px 0 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.dialog-result.v-dialog > .v-card > .v-card__text {
  padding: 10px 24px 40px 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6D7885;
}

.dialog-result.v-dialog > .v-card > .v-card__text a {
  color: #6D7885;
  text-decoration: none
}

</style>
