<template>
  <div :class="order.step !== 1 ? 'main-screen-margin' : ''">
    <v-container class="pb-2 header-block">
      <v-row>
        <v-col lg="1" md="1" sm="1" cols="2">
          <v-btn icon @click="previousStep" v-show="order.step>1" class="float-left">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col lg="10" md="10" sm="10" cols="8">
          <h3 class="text-center"> {{ [4,5].includes(order.step) ? 'Оформить заказ' : 'Узнать цену' }}</h3>
        </v-col>
        <v-col lg="1" md="1" sm="1" cols="2">
          <v-btn icon @click.stop="toggleMenu" class="float-right">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <Menu ref="refMenu"></Menu>
    </v-container>
    <order-category
      v-show="order.step===1"
      :categories="settings.categories"
      @selected="setCategory($event)"
    />
    <order-type
      v-show="order.step===2"
      :selected="order.selected"
      :types="this.categoryTypes"
      @selected="setType($event)"
      @uploaded="setUploads($event)"
    />
<!--    <order-weight-->
<!--      v-show="order.step===3"-->
<!--      :selected="order.selected"-->
<!--      :weights="settings.weights"-->
<!--      @selected="setWeight($event)"-->
<!--    />-->
    <order-add-position
      v-show="[3, 4].includes(order.step)"
      @positionAdded="setNewPosition"
    />
    <order-weight-dialog
      :show="order.step===4"
    />
    <order-user
      v-show="order.step===5"
      :user="order.user"
    />
    <v-footer padless fixed class="button-block" v-show="order.step !== 1">
      <v-container class="smallpadding">
            <div> <!--  class="text-decoration-none d-block" -->
              <v-btn
                block
                color="primary"
                elevation="2"
                :disabled="!validatedStep"
                @click="nextStep"
                class="next-step"
              >{{ buttonText }}
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <div v-show="order.step===3">
              <v-btn
                block
                color="light-grey"
                elevation="2"
                :disabled="!validatedStep"
                class="next-step call-me"
                :href="'tel:+'+ settings.company_phone"
              >
                Позвонить по телефону
              </v-btn>
            </div>
      </v-container>
    </v-footer>
    <order-success-dialog/>
    <order-error-dialog/>
  </div>
</template>
<script>
import OrderCategory from '../components/OrderCategory'
import OrderType from '../components/OrderType'
// import OrderAddress from '../components/OrderAddress'
// import OrderServices from '../components/OrderServices'
// import OrderDateTime from '../components/OrderDateTime'
import OrderUser from '../components/OrderUser'
import OrderAddPosition from '@/components/OrderAddPosition'
// import OrderWeight from '@/components/OrderWeight'
import Menu from '../components/Menu'
import OrderWeightDialog from '../components/OrderWeightDialog.vue'
import OrderSuccessDialog from '../components/OrderSuccessDialog.vue'
import OrderErrorDialog from '../components/OrderErrorDialog.vue'
// import settings from '@/mixins/settings'
import ApiClient from '../plugins/api-client'

export default {
  components: {
    // OrderWeight,
    OrderAddPosition,
    OrderUser,
    // OrderDateTime,
    // OrderServices,
    // OrderAddress,
    OrderType,
    OrderCategory,
    Menu,
    OrderWeightDialog,
    OrderSuccessDialog,
    OrderErrorDialog
  },
  // mixins: [settings],
  props: ['step'],

  data: () => ({
    settings: {},
    settings_last_updated: null,
    items: {},
    order: {
      selected: {
        category: null,
        type: null
      //  weight: null,
      //  address: null
      },
      step: 1,
      positions: [
        {
          category: null,
          type: null,
          uploads: []
        //  weight: null
        }
      ],
      weight: '',
      pickup: true,
      // services: {
      //   dismantling: false,
      //   cutting: false,
      //   loading: false,
      //   floor: 1,
      //   lift: false
      // },
      // datetime: {
      //   date: new Date().toISOString().substr(0, 10),
      //   time: null
      // },
      user: {
        name: null,
        phone: null,
        address: null
      }
    },
    submitting: false,
    showSuccessDialog: false,
    showErrorDialog: false
  }),

  created () {
    this.updateSettings()
    if (parseInt(this.step) === 1) {
      this.$ls.set('currentOrder', {})
      this.order.user = this.$ls.get('user')
    } else {
      const currentOrder = this.$ls.get('currentOrder')
      if (currentOrder.step) {
        this.order = currentOrder
        this.$router.push({
          path: '/order/' + this.order.step
        })
      }
    }
  },

  watch: {
    $route (to, from) {
      this.order.step = parseInt(to.params.step)
    },
    order: {
      handler (val) {
        this.$ls.set('currentOrder', val)
      },
      deep: true
    }
  },

  computed: {
    lastPosition: function () {
      return this.order.positions[this.order.positions.length - 1]
    },
    categoryTypes: function () {
      if (this.lastPosition.category && this.lastPosition.category.id) {
        return this.settings.types.filter(type => type.category_id === this.lastPosition.category.id)
      }
      return []
    },
    validatedStep: function () {
      if (this.order.step === 1 && this.lastPosition.category !== null) return true
      if (this.order.step === 2 && this.lastPosition.type !== null) return true
      if (this.order.step === 3) return true
      if (this.order.step === 4 && this.order.weight) return true
      if (this.order.step === 5 && this.order.user.phone && this.order.user.phone.length >= 16 && !this.submitting) return true

      return false
    },
    buttonText: function () {
      if ([1, 2].includes(this.order.step)) return 'Сколько я получу ₽'
      else if ([3, 4].includes(this.order.step)) return 'Оформить заявку'
      else return 'Отправить'
    }
  },

  methods: {
    updateSettings () {
      const now = Date.now()

      if (this.order.step < 4 && (!this.settings_last_updated || (this.settings_last_updated + 60000 < now))) {
        const that = this
        const client = new ApiClient()

        client.get('/api/settings')
          .then(function (response) {
            that.settings = response.data
            that.settings_last_updated = now
          })
          .catch(function (error) {
            console.log(error)
            // that.showErrorDialog = true
          })
      }
    },
    nextStep () {
      this.updateSettings()
      if (this.order.step < 5) {
        this.order.step += 1
        this.$router.push({
          path: '/order/' + this.order.step
        })
      } else {
        this.submit()
      }
    },
    previousStep () {
      this.updateSettings()
      if (this.order.step > 1) {
        this.order.step -= 1
        this.$router.push('/order/' + this.order.step)
      } else {
        this.$router.push('/')
      }
    },
    setNewPosition () {
      this.order.positions.push({
        category: null,
        type: null,
        uploads: [],
        weight: null
      })
      this.order.step = 1
      this.$router.push('/order/' + this.order.step)
    },
    setCategory (category) {
      this.lastPosition.category = category
      this.setType(null)
      this.order.selected.type = null
      this.order.selected.uploads = []
      this.nextStep()
    },
    setType (type) {
      this.lastPosition.type = type
      // this.setWeight(null)
      // this.order.selected.weight = null
    },
    setUploads (uploads) {
      this.lastPosition.uploads = uploads
    },
    // setWeight (weight) {
    //   this.lastPosition.weight = weight
    // },
    setPickup (pickup) {
      this.order.pickup = pickup
    },
    setAddress (address) {
      this.order.address = address
    },
    submit () {
      this.submitting = true
      const client = new ApiClient()
      // client.get('/sanctum/csrf-cookie').then(response => {})

      const order = {
        // address: this.order.address,
        // datetime: this.order.datetime,
        // services: this.order.services,
        positions: this.order.positions,
        user: this.order.user,
        weight: parseInt(this.order.weight) || 0,
        pickup: this.order.pickup
      }

      const that = this

      client.post('/api/order', order)
        .then(function (response) {
          const ordersHistory = that.$ls.get('ordersHistory')
          ordersHistory.push(response.data)
          that.$ls.set('ordersHistory', ordersHistory)
          that.$ls.set('user', that.order.user)
          that.order = {}
          that.trackSubmit()
          // that.$router.push('/result/' + response.data.token + '/' + response.data.date)
          that.showSuccessDialog = true
          setTimeout(function () {
            that.showSuccessDialog = false
            that.$router.push('/order/1')
          }, 5000)
        })
        .catch(function (error) {
          console.log(error)
          that.showErrorDialog = true
        })
        .finally(function () {
          that.submitting = false
        })
    },

    trackSubmit () {
      if (window.VK) window.VK.Goal('purchase')
      if (this.$metrika) this.$metrika.reachGoal('clickbutton')
    },

    toggleMenu () {
      this.$refs.refMenu.toggle()
    }
  }
}
</script>
<style>
.header-block {
  margin-top: 16px;
}

.header-block .v-btn--icon.v-size--default .v-icon, .header-block .v-btn--fab.v-size--default .v-icon {
  color:#000 !important;
  height: 28px;
  font-size: 28px;
  width: 28px;
}

.smallpadding {
  padding: 25px 16px 10px 16px !important;
}

h1 {
  line-height: 1em;
  font-size: 26px;
  margin-bottom: 10px;
}

h3 {
  font-size: 22px;
  font-weight: normal;
}

.v-application .elevation-2 {
  box-shadow: none !important;
}

.v-footer {
  height: 225px;
  background: #fff !important;
  align-content: flex-start;
}

.v-footer .v-btn {
  border-radius: 8px;
  margin-bottom: 12px;
}

.theme--light.v-footer {
  background-color: #fff;
  /*color: rgba(0, 0, 0, 0.87);*/
}

.main-screen-margin {
  margin-bottom: 270px;
}

</style>
