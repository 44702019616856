<template>
  <v-dialog
    v-model="$parent.showSuccessDialog"
    max-width="500px"
    content-class="dialog-result"
  >
    <v-card>
      <v-card-title>
        <span>Заявка оформлена</span>
      </v-card-title>
      <v-card-text>
        <p>В течение 10 минут перезвонит оператор</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'OrderSuccessDialog',
  props: [],
  data: () => ({})
}
</script>
<style scoped>
.dialog-result.v-dialog > .v-card > .v-card__title {
  padding: 22px 24px 0 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.dialog-result.v-dialog > .v-card > .v-card__text {
  padding: 10px 24px 64px 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6D7885;
}
</style>
