<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      content-class="dialog-position"
      persistent
      @click:outside="$parent.previousStep"
    >
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h4">Какой вес (общий)?</span>
        </v-card-title>
        <v-card-text>
          <p>Укажите примерный вес для приезда подходящей машины.</p>
          <v-text-field
            v-model="$parent.order.weight"
            outlined
            dense
            flat
            solo
            placeholder="70 кг"
            hint=""
            hide-details="false"
            background-color="#f2f3f5"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            text
            color="primary"
            elevation="2"
            class="next-step"
            @click="$parent.nextStep"
          >Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'OrderWeightDialog',
  props: ['show'],
  data: () => ({})
}
</script>
<style scoped>
.text-h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px;
}

.dialog-position {
  margin: 8px !important;
  padding: 16px !important;
}

.dialog-position.v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 16px 16px;
}

.dialog-position.v-dialog > .v-card > .v-card__text {
  padding: 0 16px;
  margin: 0;
}

.dialog-position.v-dialog > .v-card > .v-card__text .container {
  padding: 0px;
}

.dialog-position.v-dialog .v-card__actions {
  margin-bottom: 8px;
  padding: 12px 16px 8px 16px
}

.dialog-position.v-dialog  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  padding: 4px 12px
}

.dialog-position p {
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0.12px;
  padding-bottom: 5px;
}

.theme--light.v-card {
  background: #FFFFFF !important;
}

>>> .dialog-position {
  align-self: flex-end;
}

</style>
