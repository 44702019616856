<template>
  <v-container class="pb-2">
    <v-row>
      <v-col
        class="text-center"
        cols="12"
        md="12"
        lg="12"
        sm="12"
      >
        <div class="d-flex align-center justify-center pt-sm-6 illustration">
          <div class="align-center justify-center">
            <v-img
              :src="require('../assets/pickup.svg')"
            ></v-img>
          </div>
        </div>
        <template>
          <v-simple-table class="orders">
            <template v-slot:default>
              <tbody>
              <tr
                v-for="(item, i) in $parent.order.positions"
                :key="i"
              >
                <td>{{ item.type ? item.type.title : '' }}
                  <div class="typeinfo" v-if=(item.type&&item.type.info)>({{ item.type ? item.type.info : '' }})</div>
                  <div class="date">{{ currentDate }}</div>
                </td>
<!--                <td>{{ item.weight ? item.weight.title : '' }}</td>-->
                <td class="nowrap">{{ item.type ? item.type.price : '' }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="switcher">
            <v-switch
              label="С вывозом и погрузкой"
              v-model="$parent.order.pickup"
            ></v-switch>
          </div>
        </template>
        <div>
          <v-btn
            block
            elevation="2"
            outlined
            @click="$emit('positionAdded')"
            class="addmore-btn"
          >
            <v-icon size="18">mdi-plus</v-icon>
            Добавить еще
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: [],
  data: () => ({
    currentDate: null
  }),
  created: function () {
    this.currentDate = new Date().toJSON().slice(0, 10).split('-').reverse().join('.')
  }
}
</script>
<style>
.orders {
  padding-top: 46px;
  margin-left: 20px;
  margin-right: 20px;
}
.orders td {
  padding: 0 0 10px 0 !important;
  font-size: 22px !important;
  text-align: left;
  line-height: 24px;
  vertical-align: top;
  border-bottom: none !important;
}

.orders td.nowrap {
  white-space: nowrap;
  text-align: right;
}

.date {
  top: -2px;
  position: relative;
  padding-left: 34px;
  font-size: 10px;
  letter-spacing: 0.1px;
}

.typeinfo {
  font-size: 15px;
  line-height: 15px;
}

.switcher {
  padding-left: 27px;
}
.switcher .v-input--selection-controls {
  padding-top: 0px;
}

.plussize {
  font-size: 20px !important;
}

.switcher .v-label {
  font-style: normal;
  font-size: 15px !important;
  line-height: 15px;
  font-weight: 500;
}

.illustration{
  padding-top: 49px !important;
}
</style>
